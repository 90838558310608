import { useState, useMemo, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { isFree } from '@prerender/billing-shared';

import { logoUrl } from '../../assets/config';
import {
  useCharbeePlanId,
  useHasTrackingCode,
  useIsPrerenderUser,
  usePrerenderUser,
} from '../../hooks/usePrerenderUser';
import useFeatureFlag, { OVERSIGHT_FEATURE } from '../../hooks/useFeatureFlag';

import menuItems, { PAGE_KEYS } from './menuItems';
import USER_ROLES from '../../features/auth/userRoles';

const { Sider } = Layout;

const TARGET_TO_KEY = menuItems.reduce((acc, item) => {
  if (item.type === 'group') {
    item.children.forEach((child) => (acc[child.target] = child.key));
  } else {
    acc[item.target] = item.key;
  }
  return acc;
}, {});

function filterVisibleMenuItem(item, visibilityCheckerFn) {
  if (item.type === 'group') {
    const children = item.children
      .filter((child) => visibilityCheckerFn(child))
      .map(({ key, label, icon }) => ({ key, label, icon }));

    if (children.length) {
      return { ...item, children };
    }
  }

  if (visibilityCheckerFn(item)) {
    return { key: item.key, label: item.label, icon: item.icon };
  }
}

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [showMenuIcon, setShowMenuIcon] = useState(false);

  const location = useLocation();
  const oversightEnabled = useFeatureFlag([OVERSIGHT_FEATURE]);
  const hasTrackingCode = useHasTrackingCode();
  const isPrerenderUser = useIsPrerenderUser();
  const chargebeePlanId = useCharbeePlanId();
  const paidPlan = !isFree(chargebeePlanId);
  const user = usePrerenderUser();

  const shouldBeVisible = useCallback(
    (item) => {
      switch (item.key) {
        case PAGE_KEYS.integrationWizard:
          return !hasTrackingCode && user?.role !== USER_ROLES.GUEST;

        case PAGE_KEYS.domainAnalytics:
          return isPrerenderUser;

        case PAGE_KEYS.oversight:
          return oversightEnabled;

        default:
          return item.visible;
      }
    },
    [hasTrackingCode, isPrerenderUser, paidPlan]
  );

  const items = useMemo(
    () =>
      menuItems.reduce((acc, item) => {
        const filteredItem = filterVisibleMenuItem(item, shouldBeVisible);
        if (filteredItem) {
          acc.push(filteredItem);
        }
        return acc;
      }, []),
    [shouldBeVisible]
  );

  const toggleCollapsed = () => setCollapsed(!collapsed);

  const selectedKey = TARGET_TO_KEY[`/${location.pathname.split('/')[1]}`];

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      breakpoint="md"
      collapsedWidth="0"
      onBreakpoint={(broken) => {
        setCollapsed(broken);
        setShowMenuIcon(broken);
      }}
      theme="light"
      width={260}
      style={{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0, top: 0, bottom: 0 }}
      className="sidebar-layout"
    >
      {showMenuIcon && (
        <div style={{ position: 'fixed', left: 0, top: 0, bottom: 0 }}>
          <MenuOutlined onClick={toggleCollapsed} className="trigger" style={{ fontSize: 24 }} />
        </div>
      )}
      <div style={{ margin: 16 }}>
        <Link to="/">
          <img src={logoUrl} className="navbar-brand-img mx-auto" alt="Prerender" />
        </Link>
      </div>
      <Menu selectedKeys={selectedKey} mode="vertical" style={{ paddingBottom: 80 }} items={items} />
    </Sider>
  );
};

export default Sidebar;
