import { Link } from 'react-router-dom';
import { Tag, Space } from 'antd';
import {
  DashboardOutlined,
  ApiOutlined,
  GlobalOutlined,
  HistoryOutlined,
  PieChartOutlined,
  CloudOutlined,
  FileSyncOutlined,
  CheckSquareOutlined,
  GoogleOutlined,
  CreditCardOutlined,
  SettingOutlined,
  StockOutlined,
  LaptopOutlined,
  LockOutlined,
  EyeOutlined,
  TagOutlined,
  ScheduleOutlined,
} from '@ant-design/icons';

export const PAGE_KEYS = {
  billing: 'billing',
  security: 'security',
  settings: 'settings',
  integrationWizard: 'integration-wizard',
  dashboard: 'dashboard',
  rankTracker: 'rank-tracker',
  domainAnalytics: 'domain-analytics',
  deepInsight: 'deep-insight',
  cacheManager: 'cache-manager',
  domainManager: 'domain-manager',
  cdnAnalytics: 'cdn-analytics',
  sitemaps: 'sitemaps',
  scheduledRendering: 'scheduled-rendering',
  renderHistory: 'render-history',
  domain404Checker: 'domain-404-checker',
  oversight: 'oversight',
  gsc: 'gsc',
  customDeal: 'custom-deal',
  qa: 'qa',
};

// label: <LinkWithTag to="/page-url">test</LinkWithTag>;
function LinkWithTag({ to, children, tag = 'New', color = 'purple' }) {
  return (
    <Link to={to}>
      <Space size="small">
        <span>{children}</span>
        <Tag color={color}>{tag}</Tag>
      </Space>
    </Link>
  );
}

const settingsItems = [
  {
    key: PAGE_KEYS.billing,
    label: <Link to="/billing">Billing</Link>,
    icon: <CreditCardOutlined />,
    target: '/billing',
    group: 'Settings',
    visible: true,
  },
  {
    key: PAGE_KEYS.security,
    label: <Link to="/security">Security & Access</Link>,
    icon: <LockOutlined />,
    target: '/security',
    group: 'Settings',
    visible: true,
  },
  {
    key: PAGE_KEYS.settings,
    label: <Link to="/settings">Advanced Settings</Link>,
    icon: <SettingOutlined />,
    target: '/settings',
    group: 'Settings',
    visible: true,
  },
];

const menuItems = [
  {
    key: PAGE_KEYS.integrationWizard,
    label: <Link to="/integration-wizard">Integration Wizard</Link>,
    icon: <ApiOutlined />,
    target: '/integration-wizard',
    visible: false,
  },
  {
    key: PAGE_KEYS.dashboard,
    label: <Link to="/">Dashboard</Link>,
    icon: <DashboardOutlined />,
    target: '/',
    visible: true,
  },
  {
    key: PAGE_KEYS.rankTracker,
    label: <Link to="/rank-tracker">Rank Tracker</Link>,
    icon: <TagOutlined />,
    target: '/rank-tracker',
    visible: true,
  },
  {
    key: PAGE_KEYS.domainAnalytics,
    label: (
      <LinkWithTag to="/analytics" tag="Beta">
        Analytics
      </LinkWithTag>
    ),
    icon: <ScheduleOutlined />,
    target: '/analytics',
    visible: false,
  },
  {
    key: PAGE_KEYS.deepInsight,
    label: <Link to="/insight">Deep Insight</Link>,
    icon: <PieChartOutlined />,
    target: '/insight',
    visible: true,
  },
  {
    key: PAGE_KEYS.cacheManager,
    label: <Link to="/cache">Cache Manager</Link>,
    icon: <CloudOutlined />,
    target: '/cache',
    visible: true,
  },
  {
    key: PAGE_KEYS.domainManager,
    label: <Link to="/domain-manager">Domain Manager</Link>,
    icon: <LaptopOutlined />,
    target: '/domain-manager',
    visible: true,
  },
  {
    key: PAGE_KEYS.cdnAnalytics,
    label: <Link to="/crawl-stats">CDN Analytics</Link>,
    icon: <GlobalOutlined />,
    target: '/crawl-stats',
    visible: true,
  },
  {
    key: PAGE_KEYS.sitemaps,
    label: <Link to="/sitemaps">Sitemaps</Link>,
    icon: <FileSyncOutlined />,
    target: '/sitemaps',
    visible: true,
  },
  {
    key: PAGE_KEYS.scheduledRendering,
    label: <Link to="/recache-queue">Scheduled Rendering</Link>,
    icon: <HistoryOutlined />,
    target: '/recache-queue',
    visible: true,
  },
  {
    key: PAGE_KEYS.renderHistory,
    label: <Link to="/recache-stats">Render History</Link>,
    icon: <StockOutlined />,
    target: '/recache-stats',
    visible: true,
  },
  {
    key: PAGE_KEYS.domain404Checker,
    label: <Link to="/domain-404-checker">404 Checker</Link>,
    icon: <CheckSquareOutlined />,
    target: '/domain-404-checker',
    visible: true,
  },
  {
    key: PAGE_KEYS.oversight,
    label: <Link to="/oversight">Oversight</Link>,
    icon: <EyeOutlined />,
    target: '/oversight',
    visible: false,
  },
  {
    key: PAGE_KEYS.gsc,
    label: <Link to="/gsc">Google Search Console</Link>,
    icon: <GoogleOutlined />,
    target: '/gsc',
    visible: true,
  },
  // {
  //   key: 'custom-deal',
  //   label: 'Custom Deal Tracker',
  //   icon: <BarChartOutlined />,
  //   target: '/custom-deal',
  //   group: 'Experimental Features',
  //   visible: isIntegrated,
  // },
  // {
  //   key: 'qa',
  //   label: 'Quality Assurance',
  //   icon: <EyeOutlined />,
  //   target: '/qa',
  //   group: 'Experimental Features',
  //   visible: true,
  // },
  {
    type: 'group',
    key: 'settings',
    label: 'Settings',
    children: settingsItems,
  },
];

export default menuItems;
